import { useContext } from 'react';
import { GlobalDataContext } from '../../context/context'


export default function BlockContent_5() {
    const { rpdata } = useContext(GlobalDataContext)
    return (
        <section className="w-full h-auto py-10 bg-slate-50 flex flex-col justify-center items-center space-y-5">
            <h2 className='text-center'>{rpdata.dbSlogan?.[9]?.slogan}</h2>
            <div className='flex flex-row flex-wrap gap-5 justify-center'>
                {
                    rpdata?.stock?.slice(7, 11).map((item, index) => {
                        return (
                            <div key={index} className='lg:w-[550px] md:w-[350px] w-[400px] h-auto'>
                                <img alt={`images-` + index} src={item} />
                            </div>
                        );
                    })
                }
            </div>
        </section>
    );
}