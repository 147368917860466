import React, { useState } from "react";



export default function PostModal  () {
  const [showModal, setShowModal] = useState(true);
  return (
    <>
      {showModal ? (
        <>
          <div
            className="relative z-50"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div>
                    <div className="sm:flex sm:items-start">
                      <button
                        className="absolute top-3 right-3 px-2 rounded-full text-red-500 hover:text-[#00B3B3] bg-white "
                        id="modal-title"
                        onClick={() => setShowModal(false)}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                      <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Post%2FAntonio's%20Roofing%20and%20sheet%20Metal%20Llc%20--01.jpg?alt=media&token=2df5e59d-cc74-48ef-9af3-580205b580b8" 
                      alt="post"
                      className="w-full h-auto"/>
                    </div>
                  </div>
                   
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

