import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { GlobalDataContext } from '../../context/context'
import { useContext } from "react";
import { GoChevronRight } from "react-icons/go";
import { Link } from "react-router-dom";


export default function ContactNavigationVar() {

    const { rpdata } = useContext(GlobalDataContext)
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    const linksMenu = [
        {
            label: 'Home',
            route: '/',
            child: false,
        },
        {
            label: 'About',
            route: '/about',
            child: false,
        },
        {
            label: 'Services',
            route: '/services',
            child: rpdata?.autoGntLandingFromService,
        },
        {
            label: 'Gallery',
            route: '/gallery',
            child: rpdata?.customLinks,
        },
        {
            label: 'Contact',
            route: '/contact',
            child: false,
        }
    ]

    return (
        <section className="md:h-[100vh] h-auto w-full flex flex-col items-center justify-center space-y-10">
            <div className="flex md:flex-row flex-col w-[90%] h-auto justify-start">
                <div className="flex md:w-[30%] w-full h-autos space-x-10 py-5 md:py-0 items-center">
                    <div className="h-auto w-auto bg-slate-600 flex rounded-lg">
                        <FaMapMarkerAlt fontSize={80} className="p-5 text-white" />
                    </div>
                    <div>
                        <span className="text-[28px] font-semibold">{rpdata?.dbPrincipal?.location?.[0]?.address}</span>
                    </div>
                </div>
                <div className="flex md:w-1/2 w-full items-center h-autos space-x-10">
                    <div className="md:h-auto h-[80px] items-center w-auto bg-slate-600 flex rounded-lg">
                        <FaPhoneAlt fontSize={80} className="p-5 text-white" />
                    </div>
                    <div className="flex flex-col">
                        <div className="h-auto flex items-center">
                            <span className="text-[28px] font-semibold flex md:flex-row flex-col md:space-x-5 space-x-0 md:items-center items-start">{
                                rpdata?.dbPrincipal?.phones.slice(0, 2)?.map((phone, index) => {
                                    return (
                                        <li key={index} className="list-none">
                                            <a
                                                href={`tel:+1${phone.phone}`}
                                                className='flex md:flex-row flex-col items-center gap-3'
                                            >
                                                <span>{phone.phone}</span>
                                                <p>{phone.name}</p>
                                            </a>
                                        </li>
                                    )
                                })
                            }</span>
                        </div>
                        <div>
                            <ul>
                                {
                                    rpdata?.dbPrincipal?.workdays?.map((days, index) => {
                                        return (
                                            <li key={index} className="flex md:flex-row flex-col gap-3">
                                                <div className='flex items-center'>
                                                    <span className="text-[20px] text-slate-500">{days.day}</span>
                                                </div>
                                                <p className="text-[20px] text-slate-500">
                                                    {rpdata?.dbPrincipal?.workHours[index].hour}
                                                </p>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="flex w-[90%] h-auto justify-around md:flex-row flex-col">
                <div className="md:w-[25%] w-full h-auto py-5">
                    <picture className="flex justify-center items-center">
                        <img
                            src={rpdata?.dbPrincipal?.logo}
                            alt='logo'
                            className="w-full md:w-[100%] md:block hidden"
                        />
                    </picture>
                    <p className="md:text-start text-center">
                        {rpdata?.dbAbout?.[1]?.text.substring(0, 230)}
                    </p>
                </div>
                <iframe
                    src={rpdata?.dbPrincipal?.location?.[0].url}
                    loading="lazy"
                    title="Cover Locations"
                    className="md:w-[35%] w-full md:h-full h-[300px] py-5"
                />

                <div className="md:w-[30%] w-full h-full flex flex-col">
                    <p className="text-[28px] font-semibold text-red-600 text-center p-2">
                        Navigation
                    </p>
                    <ul className="pl-2 grid grid-cols-2">
                        {
                            linksMenu.map((item, index) => {
                                return (
                                    <li className="py-2" onClick={goToTop} key={index}>
                                        <Link to={item.route} className="flex text-black text-[20px] space-x-5 font-semibold">
                                            <GoChevronRight className="self-center" />
                                            <span className="pl-2">{item.label}</span>
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </section>
    );
}