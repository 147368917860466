import React from "react";

const TransparentHeader = ({ slogan, image, text }) => {
  return (
    <div
      className={`w-full md:h-[90vh] h-[100vh] bg-cover bg-center bg-no-repeat relative before:absolute before:w-full before:h-full`}
      style={{
        backgroundImage: `url("${image}")`,
        backgroundAttachment: 'fixed'
      }}
    >
      <div className="flex flex-col h-full md:w-1/2 w-full md:py-40 py-20 md:px-10 px-5 justify-center md:justify-center items-center relative bg-[#000000b6] space-y-4">
        <h2 className="text-white">{slogan}</h2>
        <p className="text-white text-center">
          {text}
        </p>
      </div>
    </div>
  );
};

export default TransparentHeader;
